import 'aframe';
import 'aframe-template-component';
import 'aframe-layout-component';
import 'aframe-event-set-component';
import 'aframe-proxy-event-component';
import { Entity, Scene } from 'aframe-react';
import React, {useState} from 'react';
import NavBar from "./NavBar";
import {Link} from "react-router-dom";

const Day1 = () => {
    const [imageSrc, setImageSrc] = useState('#hall-1');

    const handleClick = (id) => {
        // Remove border from all links
        document.querySelectorAll('.link').forEach((el) => {
            el.setAttribute('material', 'color', 'white');
        });

        // Add border to the selected link
        //const selectedEl = document.querySelector(`[data-id="${id}"]`);
        //selectedEl.setAttribute('material', 'color', 'grey');
    }

    return(
        <Scene>
            <a-assets>
                <img id="hall-1" crossOrigin="anonymous" src="/360_Warehouse03.png"/>
                <img id="hall-1-thumb" crossOrigin="anonymous" src="/512_Warehouse03.png"/>
                <img id="hall-2" crossOrigin="anonymous" src="/360_Warehouse02.png"/>
                <img id="hall-2-thumb" crossOrigin="anonymous" src="/512_Warehouse02.png"/>
                <img id="hall-3" crossOrigin="anonymous" src="/360_Warehouse01.png"/>
                <img id="hall-3-thumb" crossOrigin="anonymous" src="/512_Warehouse01.png"/>
                <img id="hall-4" crossOrigin="anonymous" src="/360_Warehouse04.png"/>
                <img id="hall-4-thumb" crossOrigin="anonymous" src="/512_Warehouse04.png"/>
                <audio id="click-sound" crossOrigin="anonymous"
                       src="https://cdn.aframe.io/360-image-gallery-boilerplate/audio/click.ogg"></audio>
            </a-assets>

            <Entity
                primitive="a-sky"
                id="image-360"
                src={imageSrc}
                animation__fade="property: components.material.material.color; type: color; from: #FFF; to: #000; dur: 300; startEvents: fade"
                animation__fadeback="property: components.material.material.color; type: color; from: #000; to: #FFF; dur: 300; startEvents: animationcomplete__fade"
            />

            {/*<Entity id="links" layout="type: line; margin: 1.5" position="-2 1 -3">*/}
            {/*    <Entity*/}
            {/*        class="link"*/}
            {/*        data-id={"#hall-1"}*/}
            {/*        geometry={{primitive: 'plane', height: 1, width: 1}}*/}
            {/*        material={{shader: 'flat', src: '#hall-1-thumb', color: 'grey'}}*/}
            {/*        position="-4 1.5 0"*/}
            {/*        sound="on: click; src: #click-sound"*/}
            {/*        event-set__mouseenter="scale: 1.2 1.2 1"*/}
            {/*        event-set__mouseleave="scale: 1 1 1"*/}
            {/*        event-set__click="_target: #image-360; _delay: 300; material.src: #hall-1"*/}
            {/*        events={{*/}
            {/*            click: () => handleClick("#hall-1")*/}
            {/*        }}*/}
            {/*        proxy-event="event: click; to: #image-360; as: fade"*/}
            {/*    />*/}
            {/*    <Entity*/}
            {/*        class="link"*/}
            {/*        data-id={"#hall-2"}*/}
            {/*        geometry={{primitive: 'plane', height: 1, width: 1}}*/}
            {/*        material={{shader: 'flat', src: '#hall-2-thumb'}}*/}
            {/*        position="-2 1.5 0"*/}
            {/*        sound="on: click; src: #click-sound"*/}
            {/*        event-set__mouseenter="scale: 1.2 1.2 1"*/}
            {/*        event-set__mouseleave="scale: 1 1 1"*/}
            {/*        event-set__click="_target: #image-360; _delay: 300; material.src: #hall-2"*/}
            {/*        events={{*/}
            {/*            click: () => handleClick("#hall-2")*/}
            {/*        }}*/}
            {/*        proxy-event="event: click; to: #image-360; as: fade"*/}
            {/*    />*/}
            {/*    <Entity*/}
            {/*        class="link"*/}
            {/*        data-id={"#hall-3"}*/}
            {/*        geometry={{primitive: 'plane', height: 1, width: 1}}*/}
            {/*        material={{shader: 'flat', src: '#hall-3-thumb'}}*/}
            {/*        position="0 1.5 0"*/}
            {/*        sound="on: click; src: #click-sound"*/}
            {/*        event-set__mouseenter="scale: 1.2 1.2 1"*/}
            {/*        event-set__mouseleave="scale: 1 1 1"*/}
            {/*        event-set__click="_target: #image-360; _delay: 300; material.src: #hall-3"*/}
            {/*        events={{*/}
            {/*            click: () => handleClick("#hall-3")*/}
            {/*        }}*/}
            {/*        proxy-event="event: click; to: #image-360; as: fade"*/}
            {/*    />*/}
            {/*    <Entity*/}
            {/*        class="link"*/}
            {/*        data-id={"#hall-4"}*/}
            {/*        geometry={{primitive: 'plane', height: 1, width: 1}}*/}
            {/*        material={{shader: 'flat', src: '#hall-4-thumb'}}*/}
            {/*        position="-1.5 1.5 0"*/}
            {/*        sound="on: click; src: #click-sound"*/}
            {/*        event-set__mouseenter="scale: 1.2 1.2 1"*/}
            {/*        event-set__mouseleave="scale: 1 1 1"*/}
            {/*        event-set__click="_target: #image-360; _delay: 300; material.src: #hall-4"*/}
            {/*        events={{*/}
            {/*            click: () => handleClick("#hall-4")*/}
            {/*        }}*/}
            {/*        proxy-event="event: click; to: #image-360; as: fade"*/}
            {/*    />*/}
            {/*</Entity>*/}

            <Entity camera look-controls cursor="rayOrigin: mouse">
                <nav className="navbar" style={{position: "sticky", top: 0, zIndex:1000}}>
                    <header>Warehouse preview: Hall</header>
                    <button className="nav-button" onClick={()=>setImageSrc('#hall-1')}>1</button>
                    <button className="nav-button" onClick={()=>setImageSrc('#hall-2')}>2</button>
                    <button className="nav-button" onClick={()=>setImageSrc('#hall-3')}>3</button>
                    <button className="nav-button" onClick={()=>setImageSrc('#hall-4')}>4</button>
                </nav>
            </Entity>
        </Scene>
    )
};
export default Day1;