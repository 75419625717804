import React from 'react';
import './NavBar.css';
import {Link} from "react-router-dom";

const NavBar = () => {
    return (
        <nav className="navbar" style={{position: "sticky", top: 0, zIndex:1000}}>
            <Link to="/warehouse-project/1">
                <button className="nav-button">Warehouse</button>
            </Link>
            <Link to="/warehouse-project/2">
                <button className="nav-button" >Canteen</button>
            </Link>
            <Link to="/warehouse-project/3">
                <button className="nav-button">Training</button>
            </Link>
        </nav>
    );
};

export default NavBar;