import 'aframe';
import 'aframe-template-component';
import 'aframe-layout-component';
import 'aframe-event-set-component';
import 'aframe-proxy-event-component';
import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Day1 from "./Day1";
import Day2 from "./Day2";
import Day3 from "./Day3";

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/warehouse-project/" element={<Day1 />} />
            <Route path="/warehouse-project/hall" element={<Day1 />} />
            <Route path="/warehouse-project/canteen" element={<Day2 />} />
            <Route path="/warehouse-project/training-room" element={<Day3 />} />
        </Routes>
    </BrowserRouter>
);
export default App;