import 'aframe';
import 'aframe-template-component';
import 'aframe-layout-component';
import 'aframe-event-set-component';
import 'aframe-proxy-event-component';
import { Entity, Scene } from 'aframe-react';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import NavBar from "./NavBar";

const Day3 = () => {
    const [imageSrc, setImageSrc] = useState('#hall-1');

    return(
        <Scene>
            <a-assets>
                <img id="hall-1" crossOrigin="anonymous" src="/360_Warehouse07.png"/>
                <img id="hall-1-thumb" crossOrigin="anonymous" src="/512_Warehouse07.png"/>
                <img id="hall-2" crossOrigin="anonymous" src="/360_Warehouse08.png"/>
                <img id="hall-2-thumb" crossOrigin="anonymous" src="/512_Warehouse08.png"/>
                <audio id="click-sound" crossOrigin="anonymous"
                       src="https://cdn.aframe.io/360-image-gallery-boilerplate/audio/click.ogg"></audio>
            </a-assets>

            <Entity
                primitive="a-sky"
                id="image-360"
                src={imageSrc}
                animation__fade="property: components.material.material.color; type: color; from: #FFF; to: #000; dur: 300; startEvents: fade"
                animation__fadeback="property: components.material.material.color; type: color; from: #000; to: #FFF; dur: 300; startEvents: animationcomplete__fade"
            />

            <Entity camera look-controls cursor="rayOrigin: mouse">
                <nav className="navbar" style={{position: "sticky", top: 0, zIndex:1000}}>
                    <header>Warehouse preview: Training</header>
                    <button className="nav-button" onClick={()=>setImageSrc('#hall-1')}>1</button>
                    <button className="nav-button" onClick={()=>setImageSrc('#hall-2')}>2</button>
                </nav>
            </Entity>
        </Scene>
    )
};
export default Day3;